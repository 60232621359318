import React from 'react'
import Header from './Header'
import './layout.css'
import './link.css'

const Layout: React.FC = ({ children }) => {
  return (
    <div className="layout">
      <Header></Header>
      <div className="content">{children}</div>
    </div>
  )
}

export default Layout
