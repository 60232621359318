import React from 'react'
import { addSeparator } from '../utils/separator'
import './header.css'
import Logo from './logo'
import Spacer from './Spacer'

interface Props {}

const pages = ['Documentation', 'GitHub']

export default function Header(props: Props) {
  return (
    <div className="navigation">
      <div className="navigation-content">
        <a href="/" className="navigation-link">
          <Logo width={40} fill={'white'} />
          <Spacer size={8} />
          Lona
        </a>
        <Spacer />
        {addSeparator(
          pages.map((name, index) => (
            <a key={index} href={`/${name}`} className="navigation-link">
              {name}
            </a>
          )),
          (index) => (
            <Spacer key={`spacer-${index}`} size={32} />
          )
        )}
      </div>
    </div>
  )
}
