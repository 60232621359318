export function addSeparator<T>(
  array: T[],
  separator: (index: number, array: T[]) => T
): T[] {
  let updated: T[] = []

  for (let i = 0; i < array.length; i++) {
    if (i > 0) {
      updated.push(separator(i, array))
    }

    updated.push(array[i])
  }

  return updated
}
